import * as React from "react";
import { Success, Layout } from "../../components";
import Seo from "../../components/Seo";

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="お問い合わせありがとうございました。" />
      <Success />
    </Layout>
  );
};

export default ContactPage;
